import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Dialog, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { DisplayResultsBy, Room, WaterlineTestResult } from '../types';
import CloseModalButton from '../../../../components/CloseModalButton';
import { StyledDialogContent, StyledDialogFooter, StyledDialogTitle } from '../../../../components/Dialog.styled';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useAppSelector } from '../../../../redux/store';
import { selectCorporateView, selectCurrentCustomerNumber, selectTeamMembers } from '../../../../redux/customer';
import { indexBy, isNumber, mapValues, prop } from 'remeda';
import { getFullNameOrEmail, selectCurrentUser } from '../../../../redux/user';
import { ToolTip } from '../../dentalenhancements/components/ToolTip';
import axios from 'axios';
import constants from '../../../../constants';
import { Edit as EditIcon, Add as AddIcon } from '@mui/icons-material';
import { getWaterLinePermissions } from '../utils';
import { selectChildCustomerTeamMembers } from '../../../../redux/childCustomer';

dayjs.extend(utc);

type FormDataField = 'retest' | 'room_id' | 'result_description' | 'product' | 'hpc_method' | 'tested_by' | 'test_date' | 'sample_number' | 'pH' | 'TDS' | 'cfu' | 'report_date';

const initialFormDataState = {
  retest: '',
  room_id: '',
  result_description: '',
  product: '',
  hpc_method: '',
  tested_by: '',
  test_date: dayjs(new Date()).toString(),
  sample_number: '',
  pH: '',
  TDS: '',
  cfu: '',
  report_date: dayjs(new Date()).toString()
}

const retestOptions = [
  'In-Office Test Result',
  'In-Office Retest Result'
];

const LogInOfficeResultModal = (props: {
  rooms: Room[];
  handleOpenAlert: (message: string) => void;
  getTestResults: () => void;
  testResult?: WaterlineTestResult;
  displayBy: DisplayResultsBy;
  getCompliance: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormDataState);
  const [formErrors, setFormErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const isCorpView = useAppSelector(selectCorporateView);
  const customerTeamMembers = useAppSelector(selectTeamMembers);
  const childCustomerTeamMembers = useAppSelector(selectChildCustomerTeamMembers);
  const teamMembers = isCorpView ? childCustomerTeamMembers : customerTeamMembers;
  const customerNumber = useAppSelector(selectCurrentCustomerNumber);
  const currentUser = useAppSelector(selectCurrentUser)

  const waterLinePermissions = getWaterLinePermissions(currentUser)

  const teamMemberIndex = useMemo(() => {
    return indexBy(teamMembers, prop('login_user_id'));
  }, [teamMembers]);

  const { rooms, handleOpenAlert, getTestResults, testResult, displayBy, getCompliance } = props;

  useEffect(() => {
    if (testResult) {
      setFormData({
        ...mapValues(formData, (value, key) => {
          if (key === 'retest') return testResult[key] ? retestOptions[1] : retestOptions[0];
          if (key === 'test_date' || key === 'report_date') return dayjs(testResult[key]).utc().format('MM/DD/YYYY');
          return testResult[key]?.toString() ?? '';
        })
      })
    }
  }, [testResult]);

  const formFields = [
    {
      label: 'Test/Retest *',
      type: 'select',
      field: 'retest',
      required: true,
      menuItems: retestOptions,
      placeholder: 'Select a Type'
    },
    {
      label: 'Select a Room/Chair Combination *',
      type: 'select',
      field: 'room_id',
      required: true,
      menuItems: rooms.map(room => {
        return {
          label: `${room.name} ${room.description}`,
          value: room.room_id
        }
      }),
      placeholder: 'Select a Room/Chair'
    },
    {
      label: 'Daily Treatment Method *',
      type: 'select',
      field: 'product',
      required: true,
      menuItems: [
        'Adec ICX',
        'Crosstex Dentapure',
        'Crosstex Sterilex',
        'Citrisil Blue',
        'Citrisil White',
        'Sterisil Cartridge Inline',
        'Sterisil Cartridge Valved',
        'Sterisil System G4',
        'Sterisil System G5',
        'Sterisil System AC/AC+',
        'Sterisil Straw Distilled',
        'Sterisil Straw Municipal',
        'Other'
      ],
      placeholder: 'Select a Treatment Method'
    },
    {
      label: 'Test Type *',
      type: 'select',
      field: 'hpc_method',
      required: true,
      menuItems: [
        'MyCheck In-Office Waterline Tests',
        'FastCheck15 In-Office Waterline Tests'
      ],
      placeholder: 'Select Type of Test Used'
    },
    {
      label: 'Team Member *',
      type: 'select',
      field: 'tested_by',
      required: true,
      menuItems: [...teamMembers]
        .sort((a, b) => (a.firstname ?? '').toLowerCase().localeCompare((b.firstname ?? '').toLowerCase()))
        .map(member => {
          return {
            label: getFullNameOrEmail(teamMemberIndex[member.login_user_id]),
            value: member.login_user_id
          }
        }),
      placeholder: 'Select Team Member'
    },
    {
      label: 'Test Date *',
      type: 'date',
      field: 'test_date',
      required: true
    },
    {
      label: 'Sample Number',
      type: 'number',
      field: 'sample_number',
      required: false
    },
    {
      label: 'pH',
      type: 'text',
      field: 'pH',
      required: false
    },
    {
      label: 'TDS',
      type: 'text',
      field: 'TDS',
      required: false
    },
    {
      label: 'CFU/ML *',
      type: 'select',
      field: 'cfu',
      required: true,
      menuItems: [
        {
          label: '<500',
          value: '499'
        },
        {
          label: '>=500',
          value: '500'
        }
      ],
      placeholder: 'Select a Range'
    },
    {
      label: 'Result Date *',
      type: 'date',
      field: 'report_date',
      required: true
    }
  ]

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const disableSubmit = useMemo(() => {
    for (let i = 0; i < formFields.length; i++) {
      const field = formFields[i].field;
      if (formFields[i].required && formData[field as FormDataField] === '') return true;
    }
    return false;
  }, [formData])

  const handleChangeField = (field: string, value: string | boolean | number | dayjs.Dayjs) => {
    let formattedValue = value;
    let resultDescription = structuredClone(formData.result_description);

    if (field === 'test_date' || field === '') formattedValue = value ? value.toString() : '';
    if (field === 'room_id' && value) {
      const matchedRoom = rooms.find(({ room_id }) => room_id === value);
      resultDescription = matchedRoom?.description ?? '';
    }

    if (value !== '' && formErrors[field]) {
      let errors = formErrors;
      delete errors[field];
      setFormErrors(errors);
    }

    setFormData({
      ...formData,
      result_description: resultDescription,
      [field]: formattedValue
    });
  }

  const validateForm = () => {
    let errors = {};

    formFields.forEach(f => {
      const value = formData[f.field as FormDataField];
      if (value === '' && f.required) errors = { ...errors, [f.field]: `${f.label.replace(' *', '')} is required` };
      if ((f.field === 'pH' || f.field === 'TDS') && value !== '' && typeof value == 'string' && !isNumber(parseInt(value))) {
        errors = { ...errors, [f.field]: `${f.label} must be a number` };
      }
    });

    return errors;
  }

  const submitForm = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      return;
    } else {
      setFormErrors({});
    }

    try {
      Object.keys(formData).forEach(key => {
        if (formData[key as FormDataField] === '') delete formData[key as FormDataField];
      });

      const retest = formData['retest'] === retestOptions[1];

      if (testResult) {
        setLoading(true);
        await axios.put(`${constants.ONTRAQ_API_URL}/api/Waterline_test_results/${testResult.test_result_id}/inOfficeWaterlineTestResult`, {
          ...formData,
          customer_number: customerNumber,
          retest
        }
        );
      } else {
        setLoading(true);
        await axios.post(`${constants.ONTRAQ_API_URL}/api/Waterline_test_results/inOfficeWaterlineTestResult`, {
          ...formData,
          customer_number: customerNumber,
          retest
        }
        );
      }

      setLoading(false);
      setFormData(initialFormDataState);
      getTestResults();
      getCompliance();
      handleClose();
    } catch (err) {
      console.error(err);
      setLoading(false);
      handleOpenAlert(`There was an error ${testResult ? 'updating' : 'creating'} In-Office Result.`);
      setFormData(initialFormDataState);
      handleClose();
    }
  }

  const addResultButton = waterLinePermissions.inOffice.add
    && (
      <Button
        style={{ marginRight: '16px' }}
        startIcon={<AddIcon />}
        variant='contained'
        onClick={handleClickOpen}
      >
        Log In-Office Result
      </Button>
    )

  const editResultButton = waterLinePermissions.inOffice.edit
    && (
      <IconButton
        onClick={handleClickOpen}
        sx={{
          width: '16px',
          height: '16px'
        }}
      >
        <EditIcon
          fontSize="small"
          sx={{
            width: '16px'
          }}
        />
      </IconButton>
    )

  return (
    <React.Fragment>
      {
        testResult ? editResultButton : addResultButton
      }
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
      >
        <CloseModalButton handleClose={handleClose} />
        <StyledDialogTitle>Log In-Office Result</StyledDialogTitle>
        <StyledDialogContent>
          <Stack spacing={'16px'} marginTop={'16px'} marginBottom={'8px'}>
            {
              formFields.map(f => {
                if (f.type === 'select') {
                  let value = formData[f.field as FormDataField];
                  return (
                    <Box key={f.field}>
                      {
                        f.field === 'room_id' ?
                          <ToolTip
                            helpText='To select a room/chair combination, please add a room/chair under manage room chair section.'
                            style={{ display: 'inline-block', marginRight: '12px' }}
                          />
                          : null
                      }
                      <FormControl fullWidth size="small" sx={{ width: f.field === 'room_id' ? '95%' : '100%' }}>
                        <InputLabel>{f.label}</InputLabel>
                        <Select
                          disabled={testResult && displayBy === 'roomChair' && f.field === 'room_id'}
                          value={value}
                          label={f.label}
                          onChange={(event) => handleChangeField(f.field, event.target.value)}
                          error={formErrors[f.field]}
                          placeholder={f.placeholder}
                        >
                          {
                            f.menuItems?.map(item => {
                              let value = typeof item === 'string' ? item : item.value;
                              let label = typeof item === 'string' ? item : item.label;
                              return (
                                <MenuItem key={value} value={value}>{label}</MenuItem>
                              )
                            })
                          }
                        </Select>
                        {formErrors[f.field] && <FormHelperText error>{formErrors[f.field]}</FormHelperText>}
                      </FormControl>
                    </Box>
                  )
                } else if (f.type === 'date') {
                  return (
                    <React.Fragment key={f.field}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                        <DatePicker
                          disabled={testResult && displayBy === 'testDate' && f.field === 'test_date'}
                          label={f.label}
                          defaultValue={dayjs(new Date())}
                          slotProps={{ textField: { size: 'small', error: formErrors[f.field] } }}
                          value={dayjs(formData[f.field as FormDataField]) ?? ''}
                          onChange={(newDate) => handleChangeField(f.field, newDate ?? '')}
                          format="MM-DD-YYYY"
                        />
                      </LocalizationProvider>
                      {formErrors[f.field] && <FormHelperText error>{formErrors[f.field]}</FormHelperText>}
                    </React.Fragment>
                  )
                } else if (f.type === 'number') {
                  return (
                    <FormControl key={f.field} fullWidth size="small">
                      <TextField
                        size="small"
                        label={f.label}
                        onChange={(event) => handleChangeField(f.field, event.target.value)}
                        value={formData[f.field as FormDataField]}
                        placeholder={f.required ? '' : 'Optional'}
                        error={formErrors[f.field]}
                        type='number'
                      />
                      {formErrors[f.field] && <FormHelperText error>{formErrors[f.field]}</FormHelperText>}
                    </FormControl>
                  )
                } else {
                  return (
                    <FormControl key={f.field} fullWidth size="small">
                      <TextField
                        size="small"
                        label={f.label}
                        onChange={(event) => handleChangeField(f.field, event.target.value)}
                        value={formData[f.field as FormDataField]}
                        placeholder={f.required ? '' : 'Optional'}
                        error={formErrors[f.field]}
                      />
                      {formErrors[f.field] && <FormHelperText error>{formErrors[f.field]}</FormHelperText>}
                    </FormControl>
                  )
                }
              })
            }
          </Stack>
        </StyledDialogContent>
        <StyledDialogFooter>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={submitForm} disabled={disableSubmit || loading}>Save</Button>
        </StyledDialogFooter>
      </Dialog>
    </React.Fragment>
  );
}

export default LogInOfficeResultModal;