import { Program } from './views/practice/dentalenhancements/types';

export interface ChildCustomerDetails {
  customer_number: string;
  customerService: CustomerService[];
  doctor_name: string;
  email: string;
  name: string;
  phone: string;
  city: string;
  state: string;
  street: string;
  street2: string;
  zip: string;
  customer_osha_hipaa_metadata?: CustomerOshaHipaaMetadata[];
  teamMembers: TeamMember[];
}

export interface Compliance {
  category: string;
  compliance_code: string;
  compliance_status: ComplianceStatus;
  message_long: string;
  message_short: string;
  priority: number;
}

export interface CustomerDetails {
  city: string;
  corporate_parent: boolean;
  customer_number: string;
  customerService: CustomerService[];
  doctor_name: string;
  email: string;
  groups: Groups[];
  name: string;
  phone?: string;
  state: string;
  street: string;
  street2?: string;
  zip: string;
}

export interface CustomerOshaHipaaMetadata {
  id: number;
  compliance_code: string;
  customer_number: string;
  hipaa_compliance_status: ComplianceStatus;
  osha_compliance_status: ComplianceStatus;
  training_compliance_status: ComplianceStatus;
  percentage_complete: number;
  updated_date: string;
  compliance: Compliance;
  year: number;
}

export interface CustomerService {
  id: string;
  isActive: boolean;
  serviceType: string;
  customerNumber: string;
}

export interface CustomerTraining {
  activation_date: string;
  created_date: string;
  customer_number: string;
  expiration_date: string;
  id: number;
  invoice_number: string;
  is_active: boolean;
  training: Training;
  training_id: number;
  updated_date: string;
}

export interface CustomerTrainingMetadata {
  compliance?: Compliance;
  customer_number: string;
  due_soon_count: number;
  id: number;
  past_due_count: string;
  training_compliance_message: string;
  training_compliance_status: number;
}

export interface Group {
  user_id: string;
  group_id: number;
  name: GroupName;
}

export interface Groups {
  group_id: number;
  name: string;
  customer_number: string;
  members: Members[];
}

export interface GroupName {
  group_id: number;
  name: string;
  customer_number: string;
}

export interface Members {
  group_id: number;
  customer_number: string;
}

export interface RefItem {
  category?: string;
  images?: string;
  is_backordered: boolean;
  is_controlled_substance: boolean;
  is_discontinued: boolean;
  is_refillable: boolean;
  is_sds: boolean;
  item_number: string;
  manufacturer_name?: string;
  name: string;
  price: number;
  subcategory?: string;
  type?: string;
}

export interface TeamMember {
  key_: number;
  user_id: string;
  customer_number: string;
  loc_id: number;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  email: string;
  access_level: string;
  account_active: boolean;
  cellphone?: string;
  position?: string;
  login_user_id: string;
  last_connection_date?: string;
  verified: boolean;
  email_verified: boolean;
  groups?: Group[];
}

export interface Training {
  active_period: number;
  ce_credits: number;
  course_id: number;
  created_date: string;
  credit_eligible: boolean;
  days_to_complete: number;
  id: number;
  is_active: boolean;
  item_number: string;
  length: string;
  name: string;
  ref_item?: RefItem;
  userTrainings?: UserTraining[];
  year: number;
}

export interface TrainingInvoiceItem {
  customer_number: string;
  invoice_item_id: number;
  sku: string;
  year: number;
}

export interface UserTraining {
  completion_date: string;
  compliance_status: number;
  course_id: string;
  created_date: string;
  customer_number: string;
  deleted_date: string;
  due_date: string;
  enrollment_date: string;
  id: number;
  is_active: boolean;
  lms_user_id: string;
  start_date: string;
  updated_date: string;
  user_id: string;
  teamMember?: TeamMember;
}

export interface UserTrainingMetadata {
  compliance: Compliance;
  completed_count: number;
  created_date: string;
  customer_number: string;
  due_soon_count: number;
  overall_compliance_status: string;
  past_due_count: number;
  update_date: string;
  user_id: string;
}

export enum ComplianceStatus {
  Complete = 1,
  Ok = 1,
  Passed = 1,
  InProgress = 2,
  Expiring = 2,
  RetestPassed = 2,
  NotStarted = 3,
  Expired = 3,
  Failed = 3,
  NotApplicable = 4,
  Inactive = 4,
  Unassigned = 4
}
