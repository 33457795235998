import axios from 'axios';
import { memo, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Box, Stack, Tab, Tabs, useTheme } from '@mui/material';
import * as R from 'remeda';
import ComplianceAlertBox from '../../../components/ComplianceAlertBox';
import ComplianceAlertMessage from '../../../components/ComplianceAlertMessage';
import ComplianceDonut from '../../../components/ComplianceDonut';
import PageHeader from '../../../components/PageHeader';
import { waterlineIcon } from '../../../icons';
import { useAppSelector } from '../../../redux/store';
import { selectCorporateView, selectCurrentCustomerNumber, selectTeamMembers } from '../../../redux/customer';
import WaterlineRooms from './WaterlineRooms';
import { ErrorNotify } from '../../../components/Notify.styled';
import { DisplayResultsBy, NonKitItem, Room, WaterlineItem } from './types';
import constants from '../../../constants';
import './Waterline.css';
import WaterlineInventory from './WaterlineInventory';
import TestResults from './components/TestResults';
import { Compliance } from '../../../types';
import LogInOfficeResultModal from './components/LogInOfficeResultModal';
import { selectChildCustomerTeamMembers } from '../../../redux/childCustomer';

const ComplianceWrapper = (props: { waterlineCompliance?: Compliance }) => {

  const { waterlineCompliance } = props;

  const complianceValues = waterlineCompliance ? [{
    compliance: {
      status: waterlineCompliance.compliance_status
    }
  }] : [];

  return (
    <Stack direction="row" alignItems="center" justifyContent="center" paddingY={2}>
      <Box data-testid="programs-donut-box">
        <ComplianceDonut image="chart-waterline" label="Waterline Management" values={complianceValues} inactiveLabel="Unassigned" />
      </Box>
      <Stack direction="row" alignItems="flex-start">
        <Stack>
          <Stack direction="row" spacing={1}>
            <ComplianceAlertBox compliance_status={waterlineCompliance?.compliance_status ?? 4} data-testid="compliance-alert-box"></ComplianceAlertBox>
            <ComplianceAlertMessage compliance_status={waterlineCompliance?.compliance_status ?? 4} message={waterlineCompliance?.message_long ?? ''} data-testid="compliance-alert-message"></ComplianceAlertMessage>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
};

const PracticeWaterline = () => {
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [testResults, setTestResults] = useState([]);
  const [inventoryItems, setInventoryItems] = useState<WaterlineItem[]>([]);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [displayResultsBy, setDisplayResultsBy] = useState<DisplayResultsBy>('roomChair');
  const [waterlineCompliance, setWaterlineCompliance] = useState<Compliance>();
  const pageTabs = [`Test Results(${testResults.length})`, `Inventory(${inventoryItems.length})`, 'Room/Chair'];
  const isCorpView = useAppSelector(selectCorporateView);
  const customerNumber = useAppSelector(selectCurrentCustomerNumber);
  const customerTeamMembers = useAppSelector(selectTeamMembers);
  const childCustomerTeamMembers = useAppSelector(selectChildCustomerTeamMembers);
  const teamMembers = isCorpView ? childCustomerTeamMembers : customerTeamMembers;

  const teamMembersIndex = useMemo(() => {
    return R.indexBy(teamMembers, R.prop('login_user_id'));
  }, [teamMembers]);

  const hasInOfficeTesting = useMemo(() => {
    return inventoryItems.some(inventoryItem => {
      const subCategory = inventoryItem?.subcategory?.toLowerCase()
      return subCategory === 'officetest'
    })
  }, [inventoryItems])

  useEffect(() => {
    if (customerNumber) {
      getTestResults();
      getInventoryItems();
      getRooms();
      getCompliance();
    }
  }, [customerNumber]);

  const getTestResults = async () => {
    const query = {
      include: {
        relation: 'waterline_test_results',
        scope: {
          where: {
            deleted_date: null
          },
          include: {
            relation: 'room',
            scope: {
              where: {
                deleted_date: null
              }
            }
          }
        }
      }
    };

    const response = await axios.get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter=${JSON.stringify(query)}`);
    setTestResults(response?.data?.waterline_test_results);
  };

  const getInventoryItems = async () => {
    const query = {
      include: {
        relation: 'nonKitItems',
        scope: {
          include: [
            'ref_item',
            {
              relation: 'waterline_inventory',
              scope: {
                include: {
                  relation: 'room'
                }
              }
            }
          ],
          where: {
            category: 'waterline'
          }
        }
      }
    };

    const { data } = await axios.get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter=${JSON.stringify(query)}`);
    const validSubcategories = ['officetest', 'straws', 'tablets', 'testing', 'treatment'];
    const items = data?.nonKitItems.filter(({ ref_item }: NonKitItem) => validSubcategories.includes(ref_item?.subcategory?.toLowerCase() || ''))
      .flatMap(({ item_number, invoice_number, is_tbr, shipped_date, ref_item, waterline_inventory }: NonKitItem) => {
        return waterline_inventory.map((waterline_item: WaterlineItem) => ({
          name: ref_item.name,
          subcategory: ref_item.subcategory,
          item_number,
          invoice_number,
          shipped_date,
          is_tbr,
          ...waterline_item
        }));
      });

    setInventoryItems(items);
  };

  const getRooms = async () => {
    const query = {
      include: {
        relation: 'waterline_rooms',
        scope: {
          where: {
            deleted_date: null
          }
        }
      }
    };

    const { data } = await axios.get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter=${JSON.stringify(query)}`);
    const rooms: Room[] = data?.waterline_rooms ?? [];
    const sortedRoom = R.sortBy(rooms, R.prop('name'));
    setRooms(sortedRoom);
  };

  const reloadAll = () => {
    getTestResults();
    getInventoryItems();
    getRooms();
  }

  const getCompliance = async () => {
    const query = {
      include: [
        {
          relation: 'waterline_item_compliance',
          scope: {
            include: ['compliance']
          }
        },
        {
          relation: 'waterline_test_compliance',
          scope: {
            include: ['compliance']
          }
        }
      ]
    };

    const response = await axios.get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter=${JSON.stringify(query)}`);
    const responseData = response?.data ?? {};

    if (responseData.waterline_test_compliance.compliance.compliance_status !== 4 &&
      (responseData.waterline_test_compliance.compliance.compliance_status >= responseData.waterline_item_compliance.compliance.compliance_status ||
        responseData.waterline_item_compliance.compliance.compliance_status === 4)) {
      const waterlineTestCompliance: Compliance = responseData.waterline_test_compliance.compliance;
      setWaterlineCompliance(waterlineTestCompliance);
    } else {
      const waterlineItemCompliance: Compliance = responseData.waterline_item_compliance.compliance;
      setWaterlineCompliance(waterlineItemCompliance);
    }
  };

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleOpenAlert = (message: string) => {
    setErrorMessage(message);
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    setErrorMessage('');
  };

  const TabContent = () => {
    const tabContents = [
      <div key="0">
        {
          testResults.length === 0 ?
            (
              <>
                <Box sx={{ marginBottom: '15px', display: 'flex', justifyContent: 'flex-end' }}>
                  {
                    hasInOfficeTesting
                    && (
                      <LogInOfficeResultModal
                        rooms={rooms}
                        handleOpenAlert={handleOpenAlert}
                        getTestResults={getTestResults}
                        displayBy={displayResultsBy}
                        getCompliance={getCompliance}
                      />
                    )
                  }
                </Box>
                <Box>
                  No active tests
                </Box>
              </>
            )
            :
            <TestResults
              testResults={testResults}
              getTestResults={getTestResults}
              setDisplayResultsBy={setDisplayResultsBy}
              displayResultsBy={displayResultsBy}
              rooms={rooms}
              teamMembersIndex={teamMembersIndex}
              handleOpenAlert={handleOpenAlert}
              getCompliance={getCompliance}
              hasInOfficeTesting={hasInOfficeTesting}
              setTab={handleTabChange}
            />
        }
      </div>,
      <WaterlineInventory key="1" rooms={rooms} inventoryItems={inventoryItems} onRefresh={getInventoryItems} onAlert={handleOpenAlert} />,
      <WaterlineRooms key="2" rooms={rooms} onRefresh={getRooms} onRefreshAll={reloadAll} onAlert={handleOpenAlert} />
    ];

    return tabContents[tabIndex];
  };

  return (
    <>
      <ErrorNotify open={showAlert} onClose={handleCloseAlert}>
        {errorMessage}
      </ErrorNotify>
      <PageHeader src={waterlineIcon} headerName={'Waterline Management'} size={'26px'} />
      <ComplianceWrapper waterlineCompliance={waterlineCompliance} />
      <Box sx={{ color: theme.palette.color50555b.main }} className="waterline-container">
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="flex flex-end m-b-16">
            <Tabs value={tabIndex} onChange={handleTabChange}>
              {pageTabs.length && pageTabs.map((tab, index) => <Tab key={index} label={tab} />)}
            </Tabs>
          </Box>
          <TabContent />
        </Box>
      </Box>
    </>
  );
};

export default PracticeWaterline;
