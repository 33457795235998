import * as XLSX from 'xlsx';
import { WaterlineItem, WaterlineTestResult } from './types';
import { ChildCustomerDetails, CustomerDetails } from '../../../types';
import { formatStandardDate, formatUtcDate, getReplacementDate } from './utils';

export const waterlineItemReport = (items: WaterlineItem[], customerDetails: Partial<ChildCustomerDetails> | Partial<CustomerDetails>, exportAll: boolean) => {
  const { city, customer_number, doctor_name, name, state, street, zip } = customerDetails;
  const wb = XLSX.utils.book_new();
  const fileName = 'waterline_inventory';
  const customerData = [
    {
      A: name
    },
    {
      A: doctor_name
    },
    {
      A: street
    },
    {
      A: `${city}, ${state} ${zip}`
    },
    {
      A: `Customer #: ${customer_number}`
    }
  ];

  const inventoryItems = items.map(({ active, activated_date, invoice_number, is_tbr, item_number, name, room, shipped_date, subcategory }) => {
    const formattedSubcategory = subcategory?.toLowerCase();
    const activatedDate = formatUtcDate(activated_date);

    let item: any = {
      Status: active ? 'Active' : 'Inactive',
      'Item Name': name ?? "",
      'Item Number': item_number,
      'Room/Chair': room?.name ?? '',
      'Start Date': formattedSubcategory === 'treatment' || formattedSubcategory === 'tablets' ? activatedDate : 'N/A',
      'Install Date': formattedSubcategory === 'straws' ? activatedDate : 'N/A',
      'Test Date': formattedSubcategory === 'testing' || formattedSubcategory === 'officetest' ? activatedDate : 'N/A',
      'Ship Date': formatStandardDate(shipped_date),
      'Replacement Date': formattedSubcategory === 'straws' ? (getReplacementDate(activatedDate || formatStandardDate(shipped_date))) : 'N/A',
      'Invoice #': invoice_number,
      'Auto-Ship': is_tbr
    }

    if(formattedSubcategory !== 'treatment' && formattedSubcategory !== 'tablets' && !exportAll) delete item['Start Date'];
    if(formattedSubcategory !== 'straws' && !exportAll) {
      delete item['Install Date'];
      delete item['Replacement Date'];
    }
    if(formattedSubcategory !== 'testing' && formattedSubcategory !== 'officetest' && !exportAll) delete item['Test Date'];
    return item;
  })
    .sort((a, b) => a['Item Name'].localeCompare(b['Item Name']));

  const ws = XLSX.utils.json_to_sheet(customerData, { skipHeader: true });

  XLSX.utils.sheet_add_json(ws, inventoryItems, { origin: 'A8' });
  XLSX.utils.book_append_sheet(wb, ws, fileName);

  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export const WaterlineTestReport = (testResults: WaterlineTestResult[], customerDetails: Partial<ChildCustomerDetails> | Partial<CustomerDetails>) => {
  const { city, customer_number, doctor_name, name, state, street, zip } = customerDetails;
  const wb = XLSX.utils.book_new();
  const fileName = 'waterline_results';
  const customerData = [
    {
      A: name
    },
    {
      A: doctor_name
    },
    {
      A: street
    },
    {
      A: `${city}, ${state} ${zip}`
    },
    {
      A: `Customer #: ${customer_number}`
    }
  ];

  const results = testResults.sort((a, b) => new Date(b.test_date).getTime() - new Date(a.test_date).getTime())
    .map(({ addressed_note, addressed_on, cfu, hpc_method, in_office_test, is_pass, location, next_steps, pH, product, receive_date, report_date, room, result_description, retest, sample_number, TDS, test_date, test_operator, test_result_id }) => {
      return {
        'Test ID': in_office_test ? 'N/A' : test_result_id,
        'Test Result': is_pass ? 'PASS' : 'FAIL',
        'In-Office/Mail-In': in_office_test ? 'In-Office' : 'Mail-In',
        'Test/Retest': retest ? 'Retest' : 'Test',
        'Sample Number': sample_number,
        'Test Type': hpc_method,
        'Room/Chair': room?.name ?? location,
        'Daily Treatment Method': product,
        'Description': result_description,
        'Team Member/Lab Tech': test_operator,
        'pH': pH,
        'TDS': TDS,
        'CFU/ml': cfu,
        'Test Date': formatStandardDate(test_date),
        'Receive Date': in_office_test ? 'N/A' : formatStandardDate(receive_date),
        'Results Date': formatStandardDate(report_date),
        'Next Steps': in_office_test ? '' : next_steps,
        'Addressed On': formatStandardDate(addressed_on),
        'CAPA': addressed_note
      };
    });

  const ws = XLSX.utils.json_to_sheet(customerData, { skipHeader: true });

  XLSX.utils.sheet_add_json(ws, results, { origin: 'A8' });
  XLSX.utils.book_append_sheet(wb, ws, fileName);

  XLSX.writeFile(wb, `${fileName}.xlsx`);
};
